<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>客户列表</span>
			</div>
			<operate-search :isSuper="false" @search="search" placeholder="请输入客户名称">
			</operate-search>
			<el-table ref="multipleTable" :data="userList" border tooltip-effect="dark" class="mt-3">
				<el-table-column prop="nickName" label="微信昵称">
				</el-table-column>
				<el-table-column prop="avataUrl" label="微信头像">
					<template slot-scope="scope">
						<el-avatar size="large" :src="scope.row.avatarUrl"></el-avatar>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="姓名">
				</el-table-column>
				<el-table-column prop="mobile" label="手机号">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="100">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
							:active-value="1" :inactive-value="0" @change="changeStatus(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="operate" label="操作">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="showForm(scope.$index, scope.row)">修改</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="pageSizeChange" @current-change="pageCurrentChange"
				:current-page="pageData.current" :page-sizes="pageData.sizes" :page-size="pageData.size"
				layout="total, sizes, prev, pager, next, jumper" :total="pageData.total" class="mt-3">
			</el-pagination>
		</el-card>
		<el-dialog :title="formTitle" :visible.sync="formVisible">
			<el-form :model="formData" :rules="rules" ref="ruleForm">
				<el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
					<el-input v-model="formData.name" autocomplete="off" size="small" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="手机号" :label-width="formLabelWidth" prop="mobile">
					<el-input v-model="formData.mobile" autocomplete="off" size="small" type="number" style="width: 80%;"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import operateSearch from "@/components/common/operate-search.vue"
	import common from "@/common/mixins/common.js"
	export default {
		components: {
			operateSearch
		},
		inject: ['layout'],
		mixins:[common],
		data() {
			return {
				preUrl:'user',
				userList: [],
				keyword:'',
				editIndex: -1,
				formTitle: '添加客户',
				formVisible: false,
				formData: {},
				formLabelWidth: '120px',
				rules: {
					name: [{
						required: true,
						message: '请输入客户名称',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}, ],
					tel: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}]
				}
			};
		},
		methods: {
			getListRes(e) {
				this.userList = e.list
				console.log(e)
			},
			getUrl() {
				let url = `/admin/${this.preUrl}/index/${this.pageData.current}?limit=${this.pageData.size}`
				if(this.keyword !== '') {
					url += '&keyword=' + this.keyword
				}
				return url
			},
			showForm(index, row) {
				this.editIndex = index
				if (this.editIndex === -1) {
					this.formTitle = '添加客户'
					this.formData = {
						name: '',
						contact: '',
						tel:'',
						status: 1
					}
				} else {
					this.formTitle = '修改客户'
					this.formData = {
						name: row.name,
						contact: row.contact,
						tel:row.tel,
						status: row.status
					}
				}
				this.formVisible = true
			},
			submit() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						let id = 0
						if (this.editIndex !== -1) {
							id = this.userList[this.editIndex].id
						}
						this.addOrEdit(this.formData,id)
						this.formVisible = false
					}
				})
			},
			search(e) {
				this.keyword = e
				this.getList()
			},
		}
	};
</script>

<style>
</style>
